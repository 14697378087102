import Vue from 'vue'
import Vuex from 'vuex'
import authentication from './modules/authentication'
import config from './modules/config'
import users from './modules/users'
import notifications from "@/store/modules/notifications";
import createPersistedState from 'vuex-persistedstate'
const vuexPersist = new createPersistedState({
  key: 'campmail',
  storage: window.localStorage,
  paths: ['authentication'],
})
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authentication,
    config,
    users,
    notifications

  },
  plugins: [
    vuexPersist
  ],
})
