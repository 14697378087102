import axios from 'axios'
import store from '../store/index'
import config from '../config'
import router from '../router/index'

export default () => {
  const axiosInstance = axios.create({
    baseURL: `${config.apiUrl}`,
    headers: {
      'jwt': `${store.state.authentication.token}`,
      'authorization': `${store.state.authentication.token2}`
    }
  });
  axiosInstance.interceptors.response.use(function (response) {

    if (response.headers.jwt !== undefined && response.headers.jwt !== store.state.authentication.token) {
      store.dispatch("authentication/setToken", response.headers.jwt)
    }
    if(response.status === 200 && response.data.message){
      store.dispatch("notifications/success", response.data.message);
    }
    return response;
  }, function (error) {

    if (error.response.status >= 500 ) {
      store.dispatch("notifications/error", error.response.data.error);
    }
    if (error.response.status === 401) {
      if (window.location.hash == '#/login' || window.location.pathname == '/login') {
        return Promise.reject(error);
      }
      if (window.location.hash == '#/resetpassword' || window.location.pathname == '/resetpassword') {
        return Promise.reject(error);
      }
      store.dispatch("notifications/error",'Season Expired Please Sign In Again');
      store.dispatch("authentication/logout");

      router.push({ name: "Login" });
      return Promise.resolve(error.response);
    } else {
      return Promise.reject(error);
    }
  });
  return axiosInstance;
}