import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: `/fullarticle`,
    name: 'FullArticle',
    props: true,
    component: () => import('../views/FullArticle.vue')
  },
  {
    path: `/myaccount`,
    name: 'MyAccount',
    component: () => import('../views/MyAccount.vue')
  },
  {
    path: `/sendmessage`,
    name: 'SendMessage',
    component: () => import('../views/SendMessage.vue')
  },
  {
    path: `/login`,
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: `/register`,
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: `/reply`,
    name: 'Reply',
    component: () => import('../views/Reply.vue')
  },
  {
    path: `/viewmessages`,
    name: 'ViewMessages',
    component: () => import('../views/ViewMessages.vue')
  },
  {
    path: `/resetpassword`,
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue')
  },

  { path: "*", component: () => import('../views/PageNotFound.vue') },

  {
    path: '/moreinfo',
    name: 'MoreInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MoreInfo.vue')
  }
]

const router = new VueRouter({
    mode: 'history',
  base: process.env.BASE_URL,
  routes
})


const openRoutes = ['Login', 'Register', 'Reply','ResetPassword'];

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)

  if (openRoutes.includes(to.name)) {
    next()
  } else if (store.state.authentication.status >= 1) {
    next()
  } else {
    next('/login')
  }

})

export default router
