import Api from './Api'

export default {

  user() {
    const user = Api().get('users/user')
    return user
  },
  addCard(credentials) {
    return Api().post('users/payment/addcc', credentials)
  },
  deleteCard(CardId) {
    return Api().delete('users/payment/delcc/' + `${CardId}`)
  },
  addCredit(cardinfo) {
    return Api().post('users/payment/addcredit', cardinfo)
  },
  addCamper(camperInfo) {
    return Api().post('users/campers/addcamper', camperInfo)
  },
  deleteCamper(camperId) {
    return Api().delete('users/campers/delcamper/' + `${camperId}`)
  },

  login(credentials) {
    return Api().post('users/login', credentials)
  },
  forgotPassword(email) {
    return Api().post('users/user/forgotpassword', email)
  },
  register(credentials) {
    return Api().post('users/register', credentials)
  },
  updateUser(credentials) {
    const updateUser = Api().post('users/user/update', credentials)
    return updateUser
  },
  verifyToken(token) {
    return Api().post('users/verifytoken', token)
  },
  resetPassword(credentials){
    return Api().post('users/resetpassword', credentials)
  }
}
