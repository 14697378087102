
const notifications = {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
        error({ commit }, error) {
            this._vm.$toast.error(error || 'Internal Server Error');
        },
        success({ commit }, message) {
            this._vm.$toast.success(message || 'success');
        },

    }}
export default notifications