
const authentication = {
    namespaced: true,
    state: {
        token: null,
        token2: null,
        status: null,
        username: null
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token
            state.token2 = payload.refreshtoken
            state.username = payload.name
            state.status = 1
        },
        setToken(state, token) {
            state.token = token

        },
        logout(state) {
            state.token2 = null
            state.status = null
            state.token = null
            state.username = null
        },
    },
    actions: {
        setToken({ commit }, token) {
            commit('setToken', token)
        },
        logout({ commit }) {
            commit('logout')
        },
        login({ commit }, payload) {
            commit('login', payload)
        },
    }}
export default authentication