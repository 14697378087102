<template>
  <div id="app">
    <div id="nav"></div>
    <router-view class="main"/>
  </div>
</template>

<script>
import Vue from "vue";
import Toast from "vue-toastification";
import config from '@/config'
import "vue-toastification/dist/index.css";
Vue.use(Toast, config.toast);
export default {
  name: "App",
  components: {},
  data: () => ({
  })
};
</script>
<style>
p, h1, h2, h3, h4 {
  font-family: 'Poppins', sans-serif;

}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.danger-alert {
  color: red;
  text-align: center;
  font-size: 2em;
}
</style>