import userServices from '@/services/Users'
//TODO
//Add this to views
const authentication = {
    namespaced: true,
    state: {
        user: null,
        userCampers: null,
        userCards: null,
    },
    mutations: {
        userInfo(state, payload) {
            [state.user] = payload.user
            payload.userCampers.forEach(
                Camper => (Camper.FullName = Camper.firstName + " " + Camper.lastName)
            );
            state.userCampers = payload.userCampers
            state.userCards = payload.userCards
        },
    },
    actions: {
        async userInfo({ commit }) {
            const userInfo = (await userServices.user()).data
            commit('userInfo', userInfo)
        },

    }}
export default authentication