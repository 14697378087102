import configService from '@/services/config'
const config = {

    namespaced: true,
    state: {
        branches: null,
        configurations: {}
    },
    getters: {

    },
    mutations: {
        config(state, config) {
            state.branches = config.branches
            state.configurations = config.configurations
        },
    },
    actions: {
        async config({ commit }) {
            const config = (await configService.getConfig()).data
            commit('config', config)
        },
    },
}
export default config


